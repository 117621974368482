body, html {
    height: 100%; /* Faz com que o HTML e o body ocupem toda a altura da tela */
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
    background-color: #f5f5f7;
    margin: 0;
    padding: 0;
    color: #1d1d1f;
    overflow-y: hidden; /* Impede o scroll vertical na página inteira */
}

/* Para a tela de produtos */
.container-produtos {
    width: 100%;
    max-width: 100%; /* Garantir que o container ocupe toda a largura sem overflow */
    padding: 10px 10px; /* Adicionando padding para espaçamento nas laterais e embaixo */
    margin: 0 auto; /* Centralizar o container */
    box-sizing: border-box; /* Inclui padding dentro da largura */
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Garante que o container ocupe a altura total da tela */
}

.title {
    font-size: 36px;
    font-weight: 300;
    color: #2C3E50;
    margin-bottom: 10px;
}

.search-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#searchInput {
    width: 300px;
    padding: 10px;
    border: 1px solid #d2d2d7;
    border-radius: 5px;
    font-size: 14px;
}

.button {
    background-color: #2C3E50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #0077ed;
}

.table-container {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
    margin-top: 0px;
    border: 1px solid #d2d2d7;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0,0,0,0.05);
    background-color: #fff;
}

table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: #fff;    
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;
}

th {
    position: sticky;
    top: 0;
    background-color: #2C3E50;
    color: #ffffff;
    font-weight: 600;
    z-index: 1;
    text-transform: uppercase;
    font-size: 0.8em;
    letter-spacing: 0.5px;
}

tr:last-child td {
    border-bottom: none;
}

tr:hover {
    background-color: #f8f8f8;
    transition: background-color 0.3s ease;
}

.edit-icon {
    cursor: pointer;
    color: #2C3E50;
    transition: color 0.3s ease;
}

.edit-icon:hover {
    color: #005bbf;
}

.modal {
    display: none;
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 10% auto;
    padding: 30px;
    border: 1px solid #888;
    width: 90%;
    max-width: 500px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.loading {
    text-align: right;
    padding: 10px;
    font-size: 14px;
    color: #86868b;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 2;
}

.item-count {
    margin-bottom: 10px;
    font-size: 16px;
    color: #86868b;
}

.info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

/* Scrollbar styles */
.table-container::-webkit-scrollbar {
    width: 8px;
}

.table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background: #a1a1a1;
}

.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.pagination button {
    background-color: #2C3E50;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

.pagination button:hover {
    background-color: #0077ed;
}
