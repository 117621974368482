:root {
    --primary-color: #2C3E50;
    --secondary-color: #5AC8FA;
    --background-color: #F2F2F7;
    --text-color: #1C1C1E;
    --border-color: #C7C7CC;
}

body, html {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    background-color: #f5f5f7;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    background-color: white;
    border-radius: 18px;
    padding: 40px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
}

.logo img {
    width: 150px; /* Ajustando a largura da logo */
    height: auto; /* Mantendo a proporção correta */
    margin-bottom: 20px;
}

h1 {
    color: #1d1d1f;
    font-size: 27px;
    margin-bottom: 20px;
}

form {
    display: flex;
    flex-direction: column;
}

input {
    margin: 10px 0; /* Uniforme entre os inputs */
    padding: 12px; /* Ajuste para espaçamento interno */
    border: 1px solid #d2d2d7;
    border-radius: 8px;
    font-size: 17px;
    outline: none;
    transition: border-color 0.3s;
}

input:focus {
    border-color: #0071e3;
}

button {
    margin-top: 20px; /* Maior espaçamento para o botão */
    padding: 15px;
    background-color: #2C3E50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 17px;
    cursor: pointer;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #0077ed;
}

.signup-link {
    margin-top: 20px;
    font-size: 14px;
    color: #86868b;
}

.signup-link a {
    color: #0066cc;
    text-decoration: none;
}
